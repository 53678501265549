<template>
  <div id="crm-chat">
    <b-overlay :show="!isAuthenticated" rounded="sm" opacity="0.75" variant="white" spinner-type="grow"
               style="min-height: 500px;margin-top: -20px">
      <template #overlay>
        <span aria-hidden="true" class="spinner-grow"><!----></span>
        <span class="mx-1" style="vertical-align: super">Connecting...</span>
      </template>
      <vue-advanced-chat
          ref="chatWindow"
          :height="screenHeight"
          :theme="layoutSkin"
          :styles="JSON.stringify(styles)"
          :current-user-id="myId.toString()"
          :room-id="roomId"
          :rooms="JSON.stringify(loadedRooms)"
          :loading-rooms="loadingRooms"
          :rooms-loaded="roomsLoaded"
          :messages="JSON.stringify(messages)"
          :messages-loaded="messagesLoaded"
          :room-message="roomMessage"
          :message-actions="JSON.stringify([ { name: 'replyMessage', title: 'Reply' },  { name: 'deleteOneMessage', title: 'Delete Message', onlyMe: true }, { name: 'selectMessages', title: 'Select',  onlyMe: true } ])"
          :menu-actions="JSON.stringify(menuActions)"
          :message-selection-actions="JSON.stringify(messageSelectionActions)"
          :templates-text="JSON.stringify(templatesText)"
          @fetch-messages="fetchMessages($event.detail[0])"
          @send-message="sendUserMessage($event.detail[0])"
          @add-room="onRequestNewRoom($event.detail[0])"
          @open-file="openFile($event.detail[0])"
          @typing-message="typingMessage($event.detail[0])"
          @message-action-handler="onMessageAction($event.detail[0])"
          @message-selection-action-handler="onMessageAction($event.detail[0])"
          @menu-action-handler="menuActionHandler($event.detail[0])"
          :media-preview-enabled="true"
      >

        <div v-for="room in loadedRooms" :slot="`room-list-avatar_${room.roomId}`" :key="room.roomId">
          <b-avatar size="40" variant="light-primary" v-bind:badge="room.type === 'ONE'" class="badge-minimal mr-1"
                    v-bind:badge-variant="room.isConnectedUser ? 'success' : 'danger'">
            <i class="fas fa-user" style="font-size: 18px" v-if="room.type === 'ONE'"></i>
            <i class="fas fa-users" style="font-size: 18px" v-if="room.type === 'MULTIPLE'"></i>
          </b-avatar>
        </div>
        <div v-for="message in messages" :slot="`spinner-icon-message-file_${message.id}`" v-bind:key="message.id">
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <div slot="spinner-icon-messages">
          <b-spinner label="Spinning" v-if="!messagesLoaded"></b-spinner>
        </div>
        <div slot="room-header-avatar">
          <b-avatar size="40" variant="light-primary" v-bind:badge="selectedRoom.type === 'ONE'" class="badge-minimal mr-1"
                    v-bind:badge-variant="selectedRoom.isConnectedUser ? 'success' : 'danger'">
            <i class="fas fa-user" style="font-size: 18px" v-if="selectedRoom.type === 'ONE'"></i>
            <i class="fas fa-users" style="font-size: 18px" v-if="selectedRoom.type === 'MULTIPLE'"></i>
          </b-avatar>
        </div>
      </vue-advanced-chat>
    </b-overlay>
    <!-- New room modal-->
    <b-modal no-fade v-model="dialog.addNewRoom.active" size="md" ref="add-room-modal" id="add-room-modal"
             title="Add Room" :hide-footer="true" no-close-on-backdrop centered>
      <div v-if="dialog.addNewRoom.active === true">
        <div>
          <b-form-input v-model="dialog.addNewRoom.data.searchUser" type="email" placeholder="Search User"
                        required></b-form-input>
          <b-form-radio-group v-model="dialog.addNewRoom.data.groupType" class="my-1 text-center"
                              @input="onNewGroupTypeChange">
            <b-form-radio value="ONE">Direct</b-form-radio>
            <b-form-radio value="MULTIPLE">Group</b-form-radio>
            <b-form-input v-model="dialog.addNewRoom.data.groupName" size="sm" class="w-auto d-inline-block"
                          placeholder="Group Name" v-bind:state="dialog.addNewRoom.data.groupNameError"
                          v-bind:disabled="dialog.addNewRoom.data.groupType != 'MULTIPLE'"></b-form-input>
          </b-form-radio-group>
        </div>
        <div class="chat-user-list">
          <perfect-scrollbar style="height: 350px" class="position-relative">
            <b-row class="py-1 chat-user-list-row border-bottom border-top cursor-pointer"
                   v-for="user in filteredUsersOnNewRoom" v-bind:key="user._id"
                   @click="()=>selectUserToNewRoom(user._id)">
              <b-col cols="3">
                <b-avatar size="40" variant="light-primary"
                          v-bind:badge="dialog.addNewRoom.data.userIds.includes(user._id) ? true : null"
                          badge-variant="success">
                  <template v-if="dialog.addNewRoom.data.userIds.includes(user._id)" #badge><i class="fas fa-check"
                                                                                               style="font-size: 10px"></i>
                  </template>
                  <i class="fas fa-user" style="font-size: 18px"></i>
                </b-avatar>
              </b-col>
              <b-col>
                {{ user.fullName }}
              </b-col>
            </b-row>
          </perfect-scrollbar>
          <div class="text-right" v-if="dialog.addNewRoom.data.groupType == 'MULTIPLE'">
            Total: {{ dialog.addNewRoom.data.userIds.length }}/50
          </div>
        </div>
        <div>
          <b-button block variant="success" :disabled="dialog.addNewRoom.data.userIds < 1" @click="requestNewRoom">Create</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit room modal-->
    <b-modal no-fade v-model="dialog.editRoom.active" size="md" ref="edit-room-modal" id="edit-room-modal"
             title="Edit Room" :hide-footer="true" no-close-on-backdrop centered>
      <div v-if="dialog.editRoom.active === true">
        <div>
          <b-form-input v-model="dialog.editRoom.data.groupName"
                        class="mb-2"
                        placeholder="Group Name" v-bind:state="dialog.editRoom.data.groupNameError"></b-form-input>
          <b-form-input v-model="dialog.editRoom.data.searchUser" type="email" placeholder="Search User"
                        required></b-form-input>
        </div>
        <div class="chat-user-list">
          <perfect-scrollbar style="height: 350px" class="position-relative">
            <b-row class="py-1 chat-user-list-row border-bottom border-top cursor-pointer"
                   v-for="user in filteredUsersOnEditRoom" v-bind:key="user._id"
                   @click="()=>editUserInRoom(user._id)">
              <b-col cols="3">
                <b-avatar size="40" variant="light-primary"
                          v-bind:badge="dialog.editRoom.data.userIds.includes(user._id) ? true : null"
                          badge-variant="success">
                  <template v-if="dialog.editRoom.data.userIds.includes(user._id)" #badge>
                    <i class="fas fa-check" style="font-size: 10px"></i>
                  </template>
                  <i class="fas fa-user" style="font-size: 18px"></i>
                </b-avatar>
              </b-col>
              <b-col>
                {{ user.fullName }}
              </b-col>
            </b-row>
          </perfect-scrollbar>
          <div class="text-right">
            Total: {{ dialog.editRoom.data.userIds.length }}/50
          </div>
        </div>
        <b-row >
          <b-col cols="6">
            <b-button block variant="danger" @click="requestDeleteRoom">Delete</b-button>
          </b-col>
          <b-col cols="6">
            <b-button block variant="success" @click="requestEditRoom">Edit</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";

function parseUser(user) {
  if (user == null) {
    return user;
  }
  return {
    _id: user.id,
    username: user.username,
    // avatar: 'assets/imgs/doe.png',
    status: {
      state: 'online',
      lastChanged: 'today, 14:30'
    }
  };
}

const darkThemeStyle = {
  general: {
    borderStyle: '1px solid #3b4253',
    backgroundInput: 'transparent',
  },
  container: {
    border: '1px solid #3b4253',
  },
  header: {
    background: '#283046',
  },
  footer: {
    background: '#283046',
    borderStyleInput: '1px solid #3b4253',
    borderInputSelected: 'rgba(242, 210, 0, 0.79)',
  },
  sidemenu: {
    backgroundHover: '#3b4253',
    backgroundActive: '#161d31',
    background: '#283046',
    borderColorSearch: '#3b4253'
  },
  content: {
    background: '#161d31'
  },
  message: {
    background: '#283046',
    color: '#b4b7bd',
    colorDate: '#b4b7bd',
  }

}

export default {
  name: "chatView",
  data() {
    return {
      roomsPerPage: 15,
      roomId: '',
      startRooms: null,
      endRooms: null,
      roomsLoaded: true,
      loadingRooms: false,
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoomId: null,
      messagesPerPage: 20,
      roomMessages: [],
      tempMessages:{},
      downloadingFiles:{},
      messagesLoaded: false,
      roomMessage: '',
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      roomsListeners: [],
      listeners: [],
      typingUsers:[],
      typingUsersTO: null,
      typingMessageCache: '',
      disableForm: false,
      dialog: {
        addNewRoom: {
          active: false,
          data: null
        },
        editRoom: {
          active: false,
          data: null
        },
      },
      removeUsers: [],
      messageSelectionActions: [{name: 'deleteMessages', title: 'Delete'}],
      templatesText: [
        {
          tag: 'help',
          text: 'This is the help'
        },
        {
          tag: 'action',
          text: 'This is the action'
        },
        {
          tag: 'action 2',
          text: 'This is the second action'
        }
      ]
      // ,dbRequestCount: 0
    }
  },
  created() {
    this.$root.$on("chat::message-updated", this.onMessagesUpdates)
    this.$root.$on("chat::message-typing", this.onMessagesTyping)
  },
  beforeDestroy() {
    this.$root.$off("chat::message-updated", this.onMessagesUpdates)
    this.$root.$off("chat::message-typing", this.onMessagesTyping)
  },
  computed: {
    ...mapGetters('appConfig',['layoutSkin']),
    ...mapGetters('data', ['getAllUsers', "getUser", "getFullName", "getUserFullName"]),
    ...mapGetters('user', ['myId']),
    ...mapGetters('chat', ['isConnected', 'isAuthenticated', 'rooms', "getMessagesByRoom", "connectedUsers"]),
    styles(){
      if(this.layoutSkin === 'dark'){
        return {container: {borderRadius: '4px'}, ...darkThemeStyle}
      }else {
        return {container: {borderRadius: '4px'}}
      }

    },
    loadedRooms() {
      //Error: Rooms object is not valid! Must contain roomId[String, Number], roomName[String] and users[Array]
      // return this.rooms.slice(0, this.roomsLoadedCount)
      let $this = this;
      return this.rooms.map(room => {
        let users = room.userIds.map(userId => ({_id: userId.toString(), username: $this.getUserFullName(userId), fullName: $this.getUserFullName(userId)}));
        let roomAdapter = {
          roomId: room.id, roomName: room.name, users, typingUsers: $this.typingUsers.filter(tu=>tu.roomId === room.id).map(tu => tu.userId),
          unreadCount: room.unreadMessages, type: room.type, lastMessage: room.lastMessage,
        };
        if (room.type === "ONE") {
          let otherUserId = room.userIds[0] === $this.myId ? room.userIds[1] : room.userIds[0];
          roomAdapter.roomName = $this.getUserFullName(otherUserId) || `(${otherUserId}) UNKNOWN`;
          roomAdapter.isConnectedUser = $this.connectedUsers.includes(otherUserId);
        }
        return roomAdapter;
      }).sort((r1,r2)=>Date.parse(r2.lastMessage?.timestamp || 0) - Date.parse(r1.lastMessage?.timestamp || 0))
    },
    loadedRoomsMap(){
      return this.loadedRooms.toObject("roomId");
    },
    selectedRoom(){
      return this.loadedRoomsMap[this.selectedRoomId] || {};
    },
    screenHeight() {
      return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 150px)'
    },
    allUsers() {
      let $this = this;
      return this.getAllUsers.map(user => ({
        _id: user.id,
        username: user.username,
        fullName: $this.getFullName(user),
      }))
    },
    allUsersMap() {
      return this.allUsers.toObject("_id");
    },
    filteredUsersOnNewRoom() {
      let $this = this;
      if(this.dialog.addNewRoom.active !== true){
        return ;
      }
      let searchInput = this.dialog.addNewRoom.data.searchUser?.toLowerCase();
      if (searchInput == null || searchInput === "") {
        let selectedUsers = $this.dialog.addNewRoom.data.userIds;
        return [...selectedUsers.map(userId => $this.allUsersMap[userId]), ...this.allUsers.filter(user => !selectedUsers.includes(user._id))];
      }
      return this.allUsers.filter(user => user.fullName.toLowerCase().includes(searchInput) || user.username.includes(searchInput));
    },
    filteredUsersOnEditRoom() {
      let $this = this;
      if(this.dialog.editRoom.active !== true){
        return ;
      }
      let searchInput = this.dialog.editRoom.data.searchUser?.toLowerCase();
      if (searchInput == null || searchInput === "") {
        let selectedUsers = $this.dialog.editRoom.data.userIds;
        return [...selectedUsers.map(userId => $this.allUsersMap[userId]), ...this.allUsers.filter(user => !selectedUsers.includes(user._id))];
      }
      return this.allUsers.filter(user => user.fullName.toLowerCase().includes(searchInput) || user.username.includes(searchInput));
    },
    messages(){
      let $this = this;
      return [...this.roomMessages, ...Object.values(this.tempMessages).filter(m=>m.roomId === $this.selectedRoomId)]
    },
    menuActions() {
      if (this.selectedRoom.type === "ONE") {
        return [];
      } else {
        return [
          {name: 'manageGroup', title: 'Manage group'},
          {name: 'deleteRoom', title: 'Exit from group'}
        ];
      }
    }
  },
  methods: {
    ...mapMutations('chat',['resetMessages']),
    ...mapActions('chat', ['retrieveMessages', "sendMessage", "seenMessage", "createRoom", "editRoom", "sendTyping", "deleteMessage"]),
    ...mapActions('document', ['uploadUserFile', 'downloadUserFile', 'getUrlForDownload']),
    fetchMessages({room, options = {}}) {
      console.log("fetchMessages", {room:JSON.parse(JSON.stringify(room)), options})
      let roomMessages = this.getMessagesByRoom(room.roomId);
      this.messagesLoaded = roomMessages.loaded;
      this.selectedRoomId = room.roomId
      if (options.reset) {
        this.resetMessages(room.roomId);
        this.retrieveMessages({
          roomId: room.roomId,
          messagesPerPage: this.messagesPerPage,
        })
      }else {
        this.retrieveMessages({
          roomId: room.roomId,
          messagesPerPage: this.messagesPerPage,
          page: roomMessages.lastPageLoaded + 1
        })
      }
    },
    onMessagesUpdates({roomId, tempId}) {
      let $this = this;
      this.removeFromTempMessages(tempId);
      if (roomId !== this.selectedRoomId) {
        console.warn("Message received from another room", roomId)
        return;
      }
      //Adapt message from BE to framework
      let room = this.getMessagesByRoom(roomId);
      this.roomMessages = room.messages.map(originalMessage => {
        let message = {
          ...originalMessage,
          _id: originalMessage.id,
          saved: true,
          distributed: true,
          seen: true,
          new: false,
          timestamp:  $this.$moment(originalMessage.timestamp).format('YYYY-MM-DD HH:mm'),
          replyMessageId: originalMessage.replyMessageId,
          senderId: originalMessage.senderId.toString()
        }
        if (originalMessage.files?.length) {
          const firstFile = originalMessage.files[0]
          message.files = originalMessage.files.map(file => ({...file, url: "", progress: $this.downloadingFiles[file.id] || -1}))
          message.files.forEach(file => $this.generateUrl(file));
        }
        $this.setIsNewMessage(message)
        return message;
      });
      //Alert as seen message
      this.seenMessage({roomId,messagesId:this.roomMessages.filter(message => message.new === true).map(message => message.id)})
      setTimeout(() => {
        $this.messagesLoaded = room.loaded;
      }, 100)
    },
    removeFromTempMessages(tempId){
      if(tempId == null){
        return;
      }
      if (this.tempMessages[tempId] != null) {
        delete this.tempMessages[tempId];
      }
    },
    setIsNewMessage(message) {
      let seenUsers = message.seenUsers || [];
      if (seenUsers.senderId === this.myId || seenUsers.includes(this.myId)) {
        message.seen = true;
        message.new = false;
      } else {
        message.seen = false;
        message.new = true;
      }
    },
    sendUserMessage({content, roomId, files, replyMessage}) {
      console.log("sendUserMessage", {content, roomId, files, replyMessage})

      let message = {content, roomId, replyMessageId: replyMessage ? replyMessage._id : null, tempId:Math.random().toString(36)}

      if (files) {
        this.handleMessageFile(message, files)
      } else {
        this.sendMessage(message)
      }
      this.tempMessages[message.tempId] =  {
        ...message,
        _id: message.tempId,
        temp: true,
        saved: false,
        distributed: false,
        seen: false,
        new: false,
        senderId: this.myId.toString(),
        files,
        roomId
      }
    },
    handleMessageFile(message, files) {
      let $this = this;
      /* audio : true blob : Blob {size: 55588, type: 'audio/mp3'} duration : 3.16 localUrl : "blob:http://localhost:8001/9b6cf002-0fc3-4dbe-a599-da71395c2ea7" name : "audio.mp3" size : 55588 type : "audio/mp3" */

      let filesUpload = files.map(file =>
          $this.uploadUserFile({
            file: $this.formatFile(file),
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              file.progress = percentage >= 99 ? -1 : percentage;
              console.log("upload file progress", {progressEvent, fileName:file.name, percentage, file:file})
            }
          })
      );
      Promise.all(filesUpload)
          .then(filesData =>
              message.documents = filesData.map(documentData => ({
                documentId: documentData.document.id,
                preview: documentData.thumb
              }))
          ).then(() => this.sendMessage(message))
    },
    formatFile(file) {
      file._id= 0;
      file.progress = 0;
      let fileName = file.name + (file.extension ? "." + file.extension : "");
      return new File([file.blob], fileName, {'type': file.type});
    },
    generateUrl(file) {
      this.getUrlForDownload({fileId: file.id, tokenParam: true, userFile: true}).then(fileUrl => {
        file.url = fileUrl || "";
      })
    },
    onRequestNewRoom() {
      this.dialog.addNewRoom.active = true
      this.dialog.addNewRoom.data = {searchUser: "", groupType: "ONE", groupName: "", userIds: [], groupNameError: null}
    },
    selectUserToNewRoom(userId) {
      if (this.dialog.addNewRoom.data.groupType === "ONE") {
        this.dialog.addNewRoom.data.userIds = [userId];
        return;
      }
      let userIds = this.dialog.addNewRoom.data.userIds;
      const index = userIds.indexOf(userId);
      if (index > -1) {
        userIds.splice(index, 1);
      } else if (userIds.length <= 50) {
        userIds.push(userId);
      }
    },
    editUserInRoom(userId) {
      if(userId === this.myId){
        return;
      }
      let userIds = this.dialog.editRoom.data.userIds;
      const index = userIds.indexOf(userId);
      if (index > -1) {
        userIds.splice(index, 1);
      } else if (userIds.length <= 50) {
        userIds.push(userId);
      }
    },
    onNewGroupTypeChange() {
      this.dialog.addNewRoom.data.userIds = [];
    },
    menuActionHandler({ roomId, action }){
      switch (action.name) {
        case 'manageGroup': {
          let room = this.selectedRoom;
          this.dialog.editRoom.data = {groupName: room.roomName, userIds: room.users.map(u => parseInt(u._id))};
          this.dialog.editRoom.active = true;
        }
      }
    },
    requestNewRoom() {
      let roomData = this.dialog.addNewRoom.data;
      if (roomData.groupType === "MULTIPLE" && (roomData.groupName == null || roomData.groupName === "")) {
        roomData.groupNameError = false;
        return;
      }
      this.createRoom({userIds: roomData.userIds, type: roomData.groupType, name: roomData.groupName})
      this.dialog.addNewRoom.active = false;
    },
    requestEditRoom() {
      let roomData = this.dialog.editRoom.data;
      this.editRoom({roomId: this.selectedRoomId, userIds: roomData.userIds, name: roomData.groupName})
      this.dialog.editRoom.active = false;
    },
    requestDeleteRoom() {
      this.editRoom({roomId: this.selectedRoomId})
      this.dialog.editRoom.active = false;
    },
    openFile(event) {
      let $this = this;
      console.log("openFile", event)
      let file = event.file.file;
      //Prevent double click
      if (this.downloadingFiles[file.id] != null) {
        return;
      }
      file.progress = 0;
      this.downloadingFiles[file.id] = 0;
      this.downloadUserFile({
        fileId: file.id, onDownloadProgress: (progressEvent) => {
          console.log(progressEvent)
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          $this.setFileDownloadStatus(event.message._id, file.id, percentage)
        }
      }).then(file => {
        $this.asyncDownloadFile(file)
      }).finally(() => {
        $this.setFileDownloadStatus(event.message._id, file.id, 100)
      })
    },
    setFileDownloadStatus(messageId, fileId, progress){
      let message = this.messages.find(message => message._id === messageId);
      if(message == null){
        console.warn("file message not found", {messageId, fileId});
      }
      let file = message.files.find(file => file.id === fileId);
      if(file == null){
        console.warn("file not found", {messageId, fileId, message});
      }
      if(progress < 99) {
        this.downloadingFiles[fileId] = file.progress = progress;
      }else {
        file.progress = -1;
        this.downloadingFiles[fileId] = null;
      }
    },
    typingMessage({roomId }){
      this.sendTyping(roomId);
    },
    onMessagesTyping({userId,roomId}){
      userId = userId.toString();
      const index = this.typingUsers.findIndex(tu=> tu.userId === userId && tu.roomId === roomId);
      if (index > -1) {
        this.typingUsers[index].time = Date.now();
      }else {
        this.typingUsers.push({userId,roomId, time: Date.now()});
      }
      this.setMessagesTypingTO()
    },
    setMessagesTypingTO(){
      if(this.typingUsersTO != null){
        return;
      }
      this.typingUsersTO = setTimeout(this.findExpiredTyping, 5*1000);
    },
    findExpiredTyping(){
      let maxTime = 1000;
      this.typingUsers =  this.typingUsers.filter(tu=> tu.time + maxTime > Date.now())
      if(this.typingUsers.length <= 0){
        this.typingUsersTO = null;
      }else {
        this.setMessagesTypingTO();
      }
    },
    onMessageAction(event) {
      if (event.action.name === 'deleteOneMessage') {
        this.deleteMessage({roomId: event.roomId,messagesId:[event.message._id]});
        return
      }
      if(event.action.name === 'deleteMessages'){
        this.deleteMessage({roomId: event.roomId, messagesId:event.messages.map(m => m._id)});
      }


    }
  },
}
</script>

<style lang="scss">
.chat-user-list-row:hover {
  background-color: #efefef;
}

.vac-loader-wrapper #vac-circle {
  display: none !important;
}

</style>
